<template>
  <v-container fill-height justify-content-start :class="{'pa-2': xsOnly, 'pa-4': smAndUp }">
    <v-layout column>
      <h2 class="display-2 raleway grey--text my-5">{{$t('sections.refund_requests')}}</h2>
      <v-layout wrap>
        <v-flex xs12 sm6 md3 lg2 :pr-3="smAndUp">
          <v-autocomplete
            :value="filter.status"
            @change="(v) => changeFilter('status', v)"
            :label="$t('fields.status')"
            :items="refundRequestStatusItems"
            :filter="autocompleteFilter"
            color="primary"
            hide-details
            clearable
            outlined
          />
        </v-flex>
        <v-flex xs12 sm6 md9 lg10 :pr-3="smAndUp">
          <v-text-field
            :value="filter.filter"
            @input="(v) => changeFilter('filter', v)"
            :placeholder="$t('refund_requests.actions.search_placeholder')"
            color="primary"
            hide-details
            clearable
            outlined
          />
        </v-flex>
      </v-layout>
      <v-layout wrap>
        <v-flex xs12 sm6 md3 lg2 mt-3 :pr-3="smAndUp">
          <date-picker
            :label="$t('refund_requests.fields.created_since')"
            @change="v => changeFilter('createdSince', v)"
            :initialValue="filter.createdSince"
            :clearable="!!filter.createdSince"
            :value="filter.createdSince"
            outlined
          />
        </v-flex>
        <v-flex xs12 sm6 md3 lg2 mt-3 :pr-3="smAndUp">
          <date-picker
            :label="$t('refund_requests.fields.created_until')"
            @change="v => changeFilter('createdUntil', v)"
            :initialValue="filter.createdUntil"
            :clearable="!!filter.createdUntil"
            :value="filter.createdUntil"
            outlined
          />
        </v-flex>
        <v-flex xs12 sm6 md3 lg2 mt-3 :pr-3="smAndUp">
          <date-picker
            :label="$t('refund_requests.fields.resolved_since')"
            @change="v => changeFilter('resolvedSince', v)"
            :initialValue="filter.resolvedSince"
            :clearable="!!filter.resolvedSince"
            :value="filter.resolvedSince"
            outlined
          />
        </v-flex>
        <v-flex xs12 sm6 md3 lg2 mt-3 :pr-3="smAndUp">
          <date-picker
            :label="$t('refund_requests.fields.resolved_until')"
            @change="v => changeFilter('resolvedUntil', v)"
            :initialValue="filter.resolvedUntil"
            :clearable="!!filter.resolvedUntil"
            :value="filter.resolvedUntil"
            outlined
          />
        </v-flex>
      </v-layout>
      <item-list
        :listId="table"
        :list="refundRequests"
        :availableHeaders="headers"
        :refreshColumnDecoration="refreshColumnDecoration"
        decorateColumnAction="refundRequests/decorateColumn"
        externalPagination
        serverside
        class="mt-3"
      >
        <template v-slot:status="{ item }">
          <v-chip label small dark :color="refundRequestColorByStatus[item.status]">
            {{$t(`refund_requests.status.${item.status}`)}}
          </v-chip>
        </template>
        <template v-slot:bankAccountImageFileId="{ item }">
          <v-btn v-if="item.bankAccountImageFileId" icon outlined @click="openBankAccountImage(item)">
            <v-icon>mdi-image</v-icon>
          </v-btn>
        </template>
        <template v-slot:appendActions="{ item }">
          <v-spacer />
          <button-menu>
            <template v-slot:content>
              <v-btn outlined :class="{ 'mb-2': !item.resolvedAt }" @click="$router.push({ name: routeNames.guest.name, params: { id: item.guestId } })">{{$t('actions.info_form', { section: $t('refund_requests.fields.guest') })}}</v-btn>
              <v-btn v-if="!item.resolvedAt" :loading="loading.acceptRefundId === item.id" outlined class="mb-2" color="success darken-1" @click="confirmAcceptTrigger = new Date(); acceptingRefundRequestId = item.id">{{$t('actions.accept')}}</v-btn>
              <v-btn v-if="!item.resolvedAt" :loading="loading.rejectRefundId === item.id" outlined color="red darken-1" @click="confirmRejectTrigger = new Date(); rejectingRefundRequestId = item.id">{{$t('actions.reject')}}</v-btn>
            </template>
          </button-menu>
        </template>
      </item-list>
    </v-layout>
    <confirm-modal
      :title="$t('refund_requests.actions.reject')"
      :text="$t('refund_requests.warnings.reject')"
      :triggerOpen="confirmRejectTrigger"
      @cancel="rejectingRefundRequestId = null"
      @accept="reject(rejectingRefundRequestId)"
    >
      <template v-slot:appendContent>
        <v-textarea
          :label="$t('refund_requests.fields.rejection_reason')"
          v-model="rejectionReason"
          color="primary"
          class="mt-4"
          auto-grow
          rows=1
        />
      </template>
    </confirm-modal>
    <confirm-modal
      :title="$t('refund_requests.actions.accept')"
      :text="$t('refund_requests.warnings.accept')"
      :triggerOpen="confirmAcceptTrigger"
      @cancel="acceptingRefundRequestId = null"
      @accept="accept(acceptingRefundRequestId)"
    />
  </v-container>
</template>

<script>
import ServerDirections from '@/config/server-directions'
import { mapState, mapGetters } from 'vuex'
import { refundRequestStatusItems, refundRequestColorByStatus } from '@/config'
import { getGuestFullName } from '@/utils/GuestUtils'
import { sanitize } from '@/utils/TextFormatUtils'
import { RefundRequestRequests } from '@/services/refund-request.requests'
import routeNames from '@/router/routes'
import dayjs from 'dayjs'

import searchMixins from '@/mixins/Search'
import vuetifyMixins from '@/mixins/Vuetify' 
import moneyFormat from '@/mixins/MoneyFormat'
import paginationMixins from '@/mixins/Pagination'

const refundRequestService = new RefundRequestRequests()

export default {
  data () {
    return {
      routeNames,
      table: 'RefundRequests',
      refreshColumnDecoration: null,
      headers: [
        { text: this.$t('fields.id'), path: 'id', show: false },
        { text: this.$t('fields.status'), path: 'status', show: true },
        { text: this.$t('fields.full_name'), path: 'fullName', show: true },
        { text: this.$t('fields.amount'), path: 'amount', show: true, getValue: ({ amount }) => `${this.formatAmount(amount)} ${this.localCurrency.name}` },
        { text: this.$t('refund_requests.fields.bank_account_number'), path: 'bankAccountNumber', show: false },
        { text: this.$t('refund_requests.fields.swift'), path: 'swift', show: false },
        { text: this.$t('fields.email'), path: 'email', show: true },
        { text: this.$t('refund_requests.fields.bank_account_image_file'), path: 'bankAccountImageFileId', show: true },
        { text: this.$t('refund_requests.fields.created_at'), path: 'createdAt', show: false, isFullDate: true, sortable: true },
        { text: this.$t('refund_requests.fields.resolved_at'), path: 'resolvedAt', show: false, isFullDate: true },
        { text: this.$t('refund_requests.fields.rejection_reason'), path: 'rejectionReason', show: false },
        { text: this.$t('refund_requests.fields.guest'), path: 'guest', show: false, getValue: ({ guest }) => guest ? getGuestFullName(guest) : null, willHaveValue: () => true, permission: 'GET_GUEST' }
      ],
      refundRequestStatusItems,
      refundRequestColorByStatus,
      filter: {
        status: null,
        createdSince: null,
        createdUntil: null,
        resolvedSince: null,
        resolvedUntil: null,
        filter: null
      },
      loading: {
        acceptRefundId: null,
        rejectRefundId: null
      },
      rejectingRefundRequestId: null,
      acceptingRefundRequestId: null,
      confirmRejectTrigger: null,
      confirmAcceptTrigger: null,
      rejectionReason: null
    }
  },
  computed: {
    ...mapState(['pagination']),
    ...mapState({
      refundRequests: state => state.refundRequests.list
    }),
    ...mapGetters({
      authToken: 'auth/authToken',
      localCurrency: 'configuration/localCurrency'
    })
  },
  methods: {
    openBankAccountImage (refund) {
      const route = ServerDirections.POS_REFUND_REQUESTS_ID_BANK_ACCOUNT_IMAGE.replace('{id}', refund.id)
      window.open(`${route}?x-api-key=${this.authToken}`)
    },
    initializeFilterValues () {
      Object.keys(this.filter).forEach((key) => {
        this.filter[key] = sessionStorage.getItem(`filterConfig:RefundRequests:${key}`) || null
      })
      this.debounceList()
    },
    list () {
      const { status, createdSince, createdUntil, resolvedSince, resolvedUntil, filter } = this.filter
      this.$store.commit('pagination/setLoading', { table: this.table, loading: true })
      this.$store.dispatch('refundRequests/list', {
        limit: this.tablePagination.pages.itemsPerPage,
        offset: (this.tablePagination.pages.page - 1) * this.tablePagination.pages.itemsPerPage,
        resolvedSince,
        resolvedUntil: resolvedUntil ? dayjs(resolvedUntil).add(dayjs().utcOffset(), 'm').set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toISOString() : null,
        createdSince,
        createdUntil: createdUntil ? dayjs(createdUntil).add(dayjs().utcOffset(), 'm').set({ hour: 23, minute: 59, second: 59, millisecond: 999 }).toISOString() : null,
        order: this.tablePagination.pages.descending ? 'ASC' : 'DESC',
        filter: filter ? this.sanitize(filter) : null,
        status,
        save: true
      }).then((response) => {
        this.updatePagination(response)
        this.refreshColumnDecoration = new Date()
      })
    },
    changeFilter (field, value) {
      this.filter[field] = value
      if (value) {
        sessionStorage.setItem(`filterConfig:RefundRequests:${field}`, value)
      } else {
        sessionStorage.removeItem(`filterConfig:RefundRequests:${field}`)
      }
      this.debounceList()
    },
    accept (id) {
      this.loading.acceptRefundId = id
      refundRequestService.accept(id).then(() => {
        this.$store.commit('state/setMessage', { text: this.$t('refund_requests.success.accept'), color: 'success' })
      }).catch((error) => {
        this.$store.commit('state/setMessage', { text: this.$t('refund_requests.errors.accept'), color: 'error' })
      }).finally(() => {
        this.acceptingRefundRequestId = null
        this.loading.acceptRefundId = null
        this.list()
      })
    },
    reject (id) {
      this.loading.rejectRefundId = id
      refundRequestService.reject(id, this.rejectionReason).then(() => {
        this.$store.commit('state/setMessage', { text: this.$t('refund_requests.success.reject'), color: 'success' })
      }).catch((error) => {
        this.$store.commit('state/setMessage', { text: this.$t('refund_requests.errors.reject'), color: 'error' })
      }).finally(() => {
        this.loading.rejectRefundId = null
        this.rejectingRefundRequestId = null
        this.rejectionReason = null
        this.list()
      })
    }
  },
  created () {
    this.list()
    this.initializeFilterValues()
  },
  components: {
    ItemList: () => import('@/components/app-components/listing/ItemList.vue'),
    ButtonMenu: () => import('@/components/app-components/buttons/ButtonMenu.vue'),
    ConfirmModal: () => import('@/components/app-components/modals/ConfirmModal.vue'),
    DatePicker: () => import('@/components/app-components/DatePicker.vue')
  },
  mixins: [
    vuetifyMixins,
    paginationMixins,
    searchMixins,
    moneyFormat
  ]
}
</script>
